import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { validateForm } from '../../components/Helper';
import { fetchData, RESET_PASSWORD } from '../../components/Service';
import { ButtonSpinner } from '../../components/Elements';
import queryString from 'query-string';

function ResetPassword() {
    const location = useLocation()
    const navigate = useNavigate();
    const [tinyloader, setTinyloader] = useState(false);

    const loginSubmission = (e) => {
        let query = queryString.parse(location.search)
        let formData = new FormData(document.getElementById('resetForm'));
        formData.append('token', query.token)
        if (validateForm(e, 'resetForm')) {
            setTinyloader(true);
            fetchData(RESET_PASSWORD, 'POST', formData, false, true, (res) => {
                setTinyloader(false);
                if (res.success) {
                    navigate('/log-in')
                }
            }, (err) => { });
        };
    }

    function showPassword() {
        var x = document.getElementById("password_confirmation");
        var y = document.getElementById("password");

        if (x.type === "password" && y.type === "password") {
            x.type = "text";
            y.type = "text";
        } else {
            x.type = "password";
            y.type = "password";
        }
    }

    return (
        <>
            <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-8 col-lg-6 col-xl-5 py-3">
                            <form className="bg-white rounded p-3 p-sm-4 p-sm-5 shadow-sm needs-validation" id="resetForm" noValidate>
                                <div className="text-center">
                                    <img src="images/logo.svg" alt="Amazio - Building Connections" className="w-100 maxw-300" width={300} height={54.36} />
                                </div>
                                <hr className="text-muted opacity-10 mx-auto" />
                                <h4 className="text-center my-3">Reset Password</h4>

                                <div className="mb-3">
                                    <label className="input-group input-group-lg">
                                        <span className="input-group-text">
                                            <svg className="icon tscale-1p1" role="img" aria-label="more">
                                                <use href="#icon_lock" />
                                            </svg>
                                        </span>
                                        <input type="password" className="form-control" minLength={8} maxLength={16} id="password" name="password" autoComplete="off" placeholder="New Password" onCopy={(e) => e.preventDefault()} onPaste={(e) => e.preventDefault()} required />
                                        <div className="invalid-feedback">The password field is required</div>
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <label className="input-group input-group-lg">
                                        <span className="input-group-text">
                                            <svg className="icon tscale-1p1" role="img" aria-label="more">
                                                <use href="#icon_lock" />
                                            </svg>
                                        </span>
                                        <input type="password" className="form-control" minLength={8} maxLength={16} id="password_confirmation" name="password_confirmation" autoComplete="off" placeholder="Confirm Password" onCopy={(e) => e.preventDefault()} onPaste={(e) => e.preventDefault()} required />
                                        <div className="invalid-feedback">The confirm password field is required</div>
                                    </label>
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <label htmlFor="showPassword" >
                                                <input type="checkbox" role="button" className="me-2 m-2" onClick={showPassword} id="showPassword" />
                                            </label>
                                            <span className="text-warning" role="button"> Show Password</span>
                                        </div>
                                        <div>
                                            Go to <Link to="/log-in" className="fw-medium" title="log-in">Login</Link>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" className="btn btn-dark btn-lg w-100 mb-3" onClick={(e) => loginSubmission(e)} disabled={tinyloader}>
                                    <ButtonSpinner load={tinyloader} btnName="Submit" />
                                </button>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword