import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { initialFormState, validateForm } from '../../components/Helper';
import { fetchData, LOGIN, GET_AUTH_USER_INFO, FORGOT_PASSWORD } from '../../components/Service';
import { Context } from '../../components/Context';
import { ButtonSpinner } from '../../components/Elements';

function Login() {
    

    const navigate = useNavigate();
    const [context, setContext] = useContext(Context)
    const [tinyloader, setTinyloader] = useState(false);
    const [forgotTinyloader, setForgotTinyloader] = useState(false);
    const [token] = useState(localStorage.getItem('amazio-token'));
    const [email, setEmail] = useState('')
    const [url] = useState('#');

    const changeHandler = (e) => {
        if (e.keyCode === 13) {
            loginSubmission(e)
        }
    }

    function showPassword() {
        var x = document.getElementById("password");

        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }

    const loginSubmission = (e) => {
        let requiredField = new FormData(document.getElementById('loginForm'));
        if (validateForm(e, "loginForm")) {
            setTinyloader(true);

            fetchData(LOGIN, 'POST', requiredField, false, true, (res) => {
                setTinyloader(false);

                if (res.records) {
                    localStorage.setItem('amazio-token', res.records.accessToken)
                    fetchData(GET_AUTH_USER_INFO, 'GET', '', true, false, (res) => {
                        if (res.records) {
                            setContext(prevState => ({
                                ...prevState,
                                auth: res.records
                            }))
                            if (res.records.user_group_id === 1) {
                                navigate(`/admin/choose-location`);
                            } else {
                                navigate('/users/select-location')
                            }
                        }
                    });

                }
            });
        };
    }

    const forgotPassword = (e) => {
        if(validateForm(e,"forgetPasswordForm")){ 
            setForgotTinyloader(true);
            fetchData(FORGOT_PASSWORD, 'POST', {user_email:email}, false, false, (res) => {
                setForgotTinyloader(false); 
                if(res.success){
                    document.querySelector('#forgotPasswordModal [data-bs-dismiss="modal"]').click()
                    navigate('/log-in')
                }
            }, (err) => {
                console.log(err)
            })

        }
    }

    useEffect(() => {
        if (token && context && context.auth) {
            navigate(`/admin/choose-location`);
        }
    }, [token, navigate, context])

    return (
        <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-5 py-3">
                        <form className="bg-white rounded p-3 p-sm-4 p-sm-5 shadow-sm needs-validation" id="loginForm" method="POST" noValidate>
                            <div className="text-center">
                                <img src="images/logo.svg" alt="Amazio - Building Connections" className="w-100 maxw-300" width={300} height={54.36} />
                            </div>
                            <hr className="text-muted opacity-10 mx-auto" />
                            <h4 className="text-center my-3">Login</h4>
                            <div className="mb-3">
                                <label className="input-group input-group-lg" htmlFor="email">
                                    <span className="input-group-text">
                                        <svg className="icon tscale-1p2" role="img" aria-label="more">
                                            <use href="#icon_user" />
                                        </svg>
                                    </span>
                                    <input type="email" className="form-control" onKeyUp={(e) => changeHandler(e)} name="email" id="email" placeholder="Username*" autoComplete="username" defaultValue="" spellCheck={false} required />
                                    <div className="invalid-feedback">The username field is required</div>
                                </label>
                            </div>
                            <div className="mb-3">
                                <label className="input-group input-group-lg">
                                    <span className="input-group-text">
                                        <svg className="icon tscale-1p1" role="img" aria-label="more">
                                            <use href="#icon_lock" />
                                        </svg>
                                    </span>
                                    <input type="password" className="form-control" onKeyUp={(e) => changeHandler(e)} name="password" id="password" placeholder="Password*" autoComplete="current-password" defaultValue="" maxLength={16} minLength={8} title="You have to enter at least 8 digit!" required />
                                    <div className="invalid-feedback">The password field is required</div>
                                </label>
                                <label htmlFor="showPassword">
                                    <input type="checkbox" role="button" className="me-2 m-2" onClick={showPassword} id="showPassword" />
                                    <span className="text-warning" role="button"> Show Password</span>
                                </label>
                            </div>
                            <button type="button" className="btn btn-dark btn-lg w-100 mb-3" onClick={(e) => loginSubmission(e)} disabled={tinyloader}>
                                <ButtonSpinner load={tinyloader} btnName="log-in" />
                            </button>
                            <div className="d-flex justify-content-between">
                                <a href={url} className="py-2 fw-medium d-block" title="Forgot Password" data-bs-toggle="modal" data-bs-target="#forgotPasswordModal" role="button">Forgot Password?</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* Forgot password Model  */}
            <div className="modal fade" id="forgotPasswordModal" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <form className="needs-validation" noValidate id="forgetPasswordForm">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Forgot Your Password?</h5>
                            <button type="button" onClick={(e) => initialFormState('forgetPasswordForm', email)} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p className="pTest">Enter the email address  that you use to log into your account and we will send you an email with a link to reset your password.</p>
                            <span className="text-danger"></span>
                            <span>
                                <div className="form-group">
                                    <input type="email" id="user_email" name="user_email" onChange={(e) => setEmail(e.target.value)} className="form-control" aria-describedby="emailHelp" placeholder="Email Address *" required />
                                    <div className="invalid-feedback">The email is required</div>
                                </div>
                            </span>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={(e) => initialFormState('forgetPasswordForm', email)}>Close</button>
                            <button type="button" className="btn btn btn-primary" onClick={(e) => forgotPassword(e)} disabled={tinyloader}>
                                <ButtonSpinner load={forgotTinyloader} btnName="Send Link" />
                            </button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login