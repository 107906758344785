import React, { useState, useEffect, useContext } from "react";
import { createRoot } from 'react-dom/client'
import Datatables, { redrawDataTable } from '../../components/Datatables';
import { MANAGE_BUSINESS_LIST, LOCATION_VERIFICATION_OPTION, LOCATION_VERIFICATION_PIN, VERIFY_PIN, CREATE_UPDATE_LOCATION_TICKET, USER_ASSIGN_LOCATION, fetchData } from "../../components/Service";
import * as Elements from "../../components/Elements";
import { Context } from "../../components/Context";
import $ from 'jquery';
import { Popover } from "bootstrap";
import { useNavigate, useLocation } from "react-router";
import LocationForm from "./LocationForm";
import _, { now } from "lodash";
import Select from "react-select";
import { dateTimeFormat, toastNotify, initialFormState, validateForm } from "../../components/Helper";

function ManageBusinessListing() {
    
    let navigate = useNavigate();
    const [context] = useContext(Context)
    const [editId, setEditId] = useState(false)
    const location = useLocation();
    const [refresh, setRefresh] = useState(false);
    const [reload, setReload] = useState(now);
    const [tinyloader, setTinyloader] = useState(false);
    const [tinyloader1, setTinyloader1] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);
    const [verificationOption, setVerificationOption] = useState(false)
    const [ticketRecord, setTicketRecord] = useState(false)
    const [userEmail, setUserEmail] = useState([])
    const [userDefaultEmail, setUserDefaultEmail] = useState([])
    const [formField, setFormField] = useState({
        gmb_store_id: '',
        phoneNumber: '',
        verificationMethod: '',
        verificationPin: ''
    })

    const verifyLocation = (id) => {
        setPageLoader(true)
        fetchData(`${LOCATION_VERIFICATION_OPTION}/${id}`, 'GET', '', true, false, (res) => {
            setPageLoader(false)
            setFormField(prevState => ({ ...prevState, gmb_store_id: id, phoneNumber: '' }))
            if (res.records && res.records.options) {
                setVerificationOption(res.records.options)
                var phoneNumber = res.records.options && res.records.options[0] && res.records.options[0].phoneNumber;
                setFormField(prevState => ({ ...prevState, phoneNumber: phoneNumber }))
            }
            else if (res.records && Array.isArray(res.records) && !res.records.length) {
                setVerificationOption(false);
                toastNotify({ 'success': res.success })
            } else {
                setFormField({
                    gmb_store_id: '',
                    phoneNumber: '',
                    verificationMethod: '',
                    verificationPin: ''
                })
                setVerificationOption(false);
                document.querySelector('#fetchVerificationOption [data-bs-dismiss="modal"]').click();
            }
        });
    }

    const getVerificationPin = () => {
        if (formField.verificationMethod) {
            setTinyloader(true)
            fetchData(LOCATION_VERIFICATION_PIN, 'POST', formField, true, false, (res) => {
                setTinyloader(false)
                if (res.success) {
                    setReload(now());
                    toastNotify({ 'success': res.success })
                }
                if (res.error) { toastNotify({ 'error': res.error }) }
            });
        } else {
            let msg = "Choose verification method";
            toastNotify({ 'error': msg })
        }
    }

    const verifyPin = () => {
        if (formField.verificationPin) {
            setTinyloader1(true)
            fetchData(VERIFY_PIN, 'POST', formField, true, false, (res) => {
                setTinyloader1(false)
                if (res.success) {
                    setFormField({
                        gmb_store_id: '',
                        phoneNumber: '',
                        verificationMethod: '',
                        verificationPin: ''
                    })
                    setVerificationOption(false);
                    document.querySelector('#fetchVerificationOption [data-bs-dismiss="modal"]').click();
                    setReload(now());
                }
                if (res.error) { toastNotify({ 'error': res.error }) }
            });
        } else {
            let msg = "Enter verification pin";
            toastNotify({ 'error': msg })
        }
    }

    useEffect(() => {
        document.getElementById("fetchVerificationOption").addEventListener('hidden.bs.modal', e => {
            setFormField(prevState => ({ ...prevState = '' }))
            setVerificationOption(false);
        });
    }, [])

    useEffect(() => {

        if (location && location.state && location.state.prevPage === 'dashboard') {
            setTimeout(function () {
                $('button[data-bs-target="#locationModal"]').trigger('click');
            }, 100)
        }
    }, [location]);

    const ViewLocation = (records) => {
        navigate(`../view-location/${records?.location_id}/${records?.site_status}`);
    }

    const [dt] = useState({
        dt_url: MANAGE_BUSINESS_LIST,
        dt_name: 'location',
        dt_export: true,
        dt_render: () => {
            setTimeout(function () {
                Array.from(document.querySelectorAll('[data-bs-toggle="popover"]')).forEach(popoverNode => new Popover(popoverNode));
            }, 0)
        },
        dt_column: [
            { data: 'location_id', name: 'location_id', title: 'Business Id', class: "text-nowrap minw-150px" },
            { data: 'buisness_name', name: 'buisness_name', title: 'Business Name', class: "text-nowrap minw-150px" },
            { data: 'location_alias', name: 'location_alias', title: 'PC Number', class: "text-nowrap minw-120px" },
            { data: 'subscription', name: 'subscription', title: 'Subscription', class: "text-nowrap minw-150px" },
            { data: 'address', name: 'address', title: 'Address', class: "text-nowrap minw-100px" },
            { data: 'city', name: 'city', title: 'City', class: "text-center minw-80px" },
            { data: 'state', name: 'state', title: 'State', class: "text-center minw-80px" },
            { data: 'zip_code', name: 'zip_code', title: 'Zip', class: "text-center minw-80px" },
            { data: 'gmb_status', name: 'gmb_status', title: 'GMB Status', class: "text-center minw-100px" },
            { data: 'site_status', name: 'yext_locations.status', title: 'GMB Sync', class: "text-center minw-100px" },
            { data: 'action', name: 'action', title: 'Action', class: "text-nowrap text-center noExport", sortable: false, searchable: false, orderable: false }
        ],
        dt_column_defs: [

            {
                targets: 8,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        records && records.gmb_status ?
                            <span className={`badge rounded-pill bg-${records.gmb_status === 'Verified' ? 'success' : records.gmb_status === 'Verification Required' ? 'primary' : records.gmb_status === 'Duplicate' ? 'warning' : records.gmb_status === 'Code Requested' ? 'info' : 'danger'}`} onClick={e => (records.gmb_status === 'Verification Required' || records.gmb_status === 'Code Requested' ? verifyLocation(records.gmb_store_id) : false)} role={records.gmb_status === 'Verification Required' || records.gmb_status === 'Code Requested' ? 'button' : ''} data-bs-target={records.gmb_status === 'Verification Required' || records.gmb_status === 'Code Requested' ? '#fetchVerificationOption' : ''} data-bs-toggle={records.gmb_status === 'Verification Required' || records.gmb_status === 'Code Requested' ? 'modal' : ''}>{records && _.capitalize(_.replace(records.gmb_status, '_', ' '))}</span>
                            : 'N/A'
                    )
                }
            },
            {
                //create popover for gmb status if status is not live
                targets: 9,
                createdCell: (td, cellData, records, row, col) => {
                    if (records?.site_status === 'LIVE') {
                        createRoot(td).render(
                            <div className="text-nowrap text-center ">
                                <span className="badge bg-success">Live</span>
                            </div>
                        )
                    } else {
                        createRoot(td).render(
                            <div className="text-nowrap text-center ">
                                {/* keep popover open until click somewhere else  */}
                                <span className="badge bg-danger" data-bs-content="${records?.site_message  ?? 'N/A'}" data-bs-toggle="popover" data-bs-trigger="focus hover"
                                    data-bs-template={`<div class='popover shadow' role='popover'><div class='popover-arrow'></div><div class='popover-inner p-2 '>${records?.site_message ?? 'N/A'}</div></div>`}
                                >{records.site_status ? _.capitalize(records.site_status) : 'N/A'}</span>
                            </div>
                        )
                    }
                }
            },
            {
                targets: 10,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        <div className="dropdown">
                            <button className="btn btn-light" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="bi bi-three-dots-vertical"></i>
                            </button>
                            <ul className="dropdown-menu button-issues listingMenuToggle">
                                <div className="d-flex justify-content-center align-items-center text-nowrap">
                                    {context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" ?
                                        context.auth.permission && context.auth.permission.map((item, key) => {
                                            return (
                                                <div key={key}>
                                                    {item.permission === "Manage Location" &&
                                                        <>
                                                            {item.edit === "yes" ?

                                                                <button type="button" className="btn btn-sm text-success" data-bs-toggle="modal" data-bs-target="#locationModal" onClick={() => { setEditId(false); setEditId(records.location_id) }}>
                                                                    <svg className="icon fs-5" role="img"><use href="#icon_edit" /></svg>
                                                                </button>

                                                                :
                                                                <Elements.DisableButton btnType="success" icon="#icon_edit" />
                                                            }
                                                        </>
                                                    }
                                                    {item.permission === "Business listing" &&
                                                        <>
                                                            {item.view === "yes" ?

                                                                <button type="button" className="btn btn-sm text-primary" onClick={() => ViewLocation(records)}>
                                                                    <svg className="icon fs-5" role="img"><use href="#icon_eye" /></svg>
                                                                </button>

                                                                :
                                                                <Elements.DisableButton btnType="primary" icon="#icon_eye" />
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            )
                                        })
                                        :
                                        <>
                                            <button type="button" className="btn btn-sm text-success" data-bs-toggle="modal" data-bs-target="#locationModal" onClick={() => { setEditId(false); setEditId(records.location_id) }}>
                                                <svg className="icon fs-5" role="img"><use href="#icon_edit" /></svg>
                                            </button>
                                            <button type="button" className="btn btn-sm text-primary" onClick={() => ViewLocation(records)}>
                                                <svg className="icon fs-5" role="img"><use href="#icon_eye" /></svg>
                                            </button>
                                        </>
                                    }
                                        <button type="button" className="btn btn-sm text-dark" data-bs-target="#locationTicket" data-bs-toggle="modal" onClick={() => setTicketRecord(records)}>
                                            <i className="bi bi-chat-quote fs-5"></i> 
                                        </button>
                                </div>
                            </ul>
                        </div>
                    </>
                    )
                }
            }
        ]
    });

    window.resetLocationId = () => {
        setEditId(editId ? false : true)
    }

    useEffect(() => {
        if (dt) {
            redrawDataTable(dt);
        }

        if (!$('.dt-custom-filter button').hasClass('add-new')) {
            {
                context && context.auth && context.auth.user_group_id === 1 && context.auth.super_admin === "no" ?
                context.auth.permission && context.auth.permission.map((item, key) => {
                    return (
                        <div key={key}>
                            {item.permission === "Manage Location" &&
                                <>
                                    {item.add === "yes" ?

                                        $('.dt-custom-filter').append(`<button type="button" class="btn btn-dark px-3 text-nowrap me-2 rounded-0 add-new" data-bs-toggle="modal" data-bs-target="#locationModal" onclick="resetLocationId()">
                                            <svg class="icon tscale-1p3 me-1" role="img"><use href="#icon_plus"></use></svg> Add New
                                        </button>`)
                                        :
                                        ''
                                    }
                                </>
                            }
                        </div>
                    )
                })
                :
                $('.dt-custom-filter').append(`<button type="button" class="btn btn-dark px-3 text-nowrap me-2 rounded-0 add-new" data-bs-toggle="modal" data-bs-target="#locationModal" onclick="resetLocationId()">
                    <svg class="icon tscale-1p3 me-1" role="img"><use href="#icon_plus"></use></svg> Add New
                </button>`);
            }
        }

    }, [dt, refresh, reload])

    const saveLocationTicket = (e) => {
        e.preventDefault()
        if (validateForm(e, 'locationTicketForm')) {
            setTinyloader(true)
            let formData = new FormData(document.getElementById('locationTicketForm'));
            formData.append('active_location_id', ticketRecord && ticketRecord.location_id);

            fetchData(CREATE_UPDATE_LOCATION_TICKET, 'POST', formData, true, true, (res) => {
                setTinyloader(false)
                if (res.success) {
                    initialFormState('locationTicketForm')
                    setTicketRecord(false)
                    setReload(now());
                    document.querySelector('#locationTicket [data-bs-dismiss="modal"]').click();
                }
            });
        }
    }

    const selectUserEmail = (e) => {
        setUserDefaultEmail(e ?? false)
    }

    useEffect(() => {
        if (ticketRecord && ticketRecord.location_id) {
            fetchData(`${USER_ASSIGN_LOCATION}/${ticketRecord.location_id}`, 'GET', '', true, false, (res) => {
                if (res && res.records && res.records.length > 0) {
                    setUserEmail([...userDefaultEmail, ...res.records]);
                } else {

                    setUserEmail(userDefaultEmail)
                }
            });
        }

        setUserDefaultEmail([
            {
                "value": process.env.REACT_APP_AMAZIO_SALES_MAIL,
                "label": "Amazio Sales Team"
            },
            {
                "value": process.env.REACT_APP_AMAZIO_TECH_MAIL,
                "label": "Amazio Tech Team"
            },
            {
                "value": process.env.REACT_APP_AMAZIO_MEGAN_MAIL,
                "label": "Amazio Megan's Mail"
            }
        ])

    }, [ticketRecord])

    return (
        <>
            <Elements.ListSection title="Manage All Locations">
                <div className="col-sm-12">
                    <div className="card border-0 shadow-sm rounded-10">
                        <div className="card-body">
                            <Datatables dt_name="location" dataPageLength="15" />
                        </div>
                    </div>
                </div>
            </Elements.ListSection>

            <Elements.ModalSection title={(editId && typeof (editId) !== "boolean") ? "Edit Location" : "Add Location"} modal_id="locationModal" size="xl">
                <input id="openLocationModal" type="hidden" data-bs-toggle="modal" data-bs-target="#locationModal" />
                <LocationForm editId={editId} modalId="openLocationModal" refresh={setRefresh} />
            </Elements.ModalSection>

            <Elements.ModalSection title="Location Verification" modal_id="fetchVerificationOption" size="md" page_loader={false}>
                {pageLoader ?
                    <div className="text-center py-5">
                        <div className="spinner-border" style={{ width: '3rem', height: '3rem' }} role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <p className="mt-3">Please wait for response. This may take a while...</p>
                    </div>
                    :
                    <form className="needs-validation p-3" id="fetchVerificationOption" method="post" noValidate>
                        {formField.phoneNumber && <div id="phoneNumber">Phone Number: {formField.phoneNumber}</div>}
                        <div className="row">
                            <input type="text" className="form-control mt-2 d-none" defaultValue={formField.gmb_store_id} id="gmb_store_id" name="gmb_store_id" />
                            {verificationOption && verificationOption.length > 0 &&
                                <>
                                    <label htmlFor="verificationOption" className="col-md-5 col-form-label mt-3">Verification Option<strong className="text-danger">*</strong>:</label>
                                    <div className="col-md-7 align-self-center mt-3">
                                        {verificationOption.map((ele, num) => {
                                            return (
                                                <span key={num}>
                                                    {ele.verificationMethod === 'PHONE_CALL' && <><input className="form-check-input" type="radio" name="verificationOption" value='phone_call' onChange={(e) => setFormField((prevState) => ({ ...prevState, verificationMethod: e.target.value }))} /> Phone Call </>}
                                                    {ele.verificationMethod === 'SMS' && <><input className="form-check-input ms-3" type="radio" name="verificationOption" value='sms' onChange={(e) => setFormField((prevState) => ({ ...prevState, verificationMethod: e.target.value }))} /> SMS </>}
                                                </span>
                                            )
                                        })}
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <div className="col-md-12 text-left">
                                            <button type="button" className="btn btn-primary minw-100" onClick={getVerificationPin} disabled={tinyloader}>
                                                <Elements.ButtonSpinner load={tinyloader} btnName="Send PIN" />
                                            </button>
                                        </div>
                                    </div>
                                </>
                            }

                            {/* {verificationPin && <> */}
                            <label htmlFor="otp" className="col-md-3 col-form-label mt-3">Enter PIN<strong className="text-danger">*</strong>:</label>
                            <div className="col-md-9 mb-1 mt-2">
                                <input className="form-control mt-2" type="text" onKeyPress={e => { if (!/[0-9]/.test(e.key)) { e.preventDefault(); } }} id="otp" onChange={(e) => setFormField((prevState) => ({ ...prevState, verificationPin: e.target.value }))} />
                            </div>
                            <div className="col-md-12 mt-4">
                                <div className="col-md-12 text-end">
                                    <button type="button" className="btn btn-primary minw-100" onClick={verifyPin} disabled={tinyloader1}>
                                        <Elements.ButtonSpinner load={tinyloader1} btnName="Verify" />
                                    </button>
                                    <button type="button" className="btn btn-outline-danger px-4 ms-2" data-bs-close="add" onClick={(e) => { setFormField(prevState => ({ ...prevState = '' })); setVerificationOption(false) }} data-bs-dismiss="modal" aria-label="Close">Close</button>
                                </div>
                            </div>
                            {/* </>
                            } */}

                        </div>
                    </form>
                }
            </Elements.ModalSection>
            <Elements.ModalSection title="Location Ticket" modal_id="locationTicket" size="xl" form_id="locationTicketForm" setData={''}>
                <form className="need-validation" noValidate id="locationTicketForm">
                    <div className="row">
                        <div className="col-md-5 border-end pe-3 pb-5" style={{ height: "650px" }}>
                            <div className="col-md-12 mt-3">
                                <label htmlFor="location_name">Location Name</label>
                                <input type="text" className="form-control mt-2" id="location_name" readOnly value={`${ticketRecord.buisness_name} ${ticketRecord.location_id ? `- ${ticketRecord.location_id}` : ''}`} />
                            </div>
                            <div className="col-md-12 mt-3">
                                <label htmlFor="emailuser">User</label>
                                <Select
                                    value={userDefaultEmail}
                                    name="email[]"
                                    isClearable={true}
                                    closeMenuOnSelect={false}
                                    options={userEmail ?? []}
                                    isMulti={true}
                                    onChange={(e) => selectUserEmail(e)}
                                    className="basic-multi-select react-select mt-2"
                                    id="email"
                                    required
                                />
                            </div>
                            <div className="col-md-12 mt-3">
                                <label htmlFor="status">Location Status<sup className="text-danger fw-bold"> *</sup></label>
                                <select name="status" id="status" className="form-select form-control mt-2" required >
                                    <option value="">Choose Status</option>
                                    <option value="sync" >Sync</option>
                                    <option value="processing" >In Process</option>
                                    <option value="not_sync" >Not Sync</option>
                                    <option value="opted_out">Opted Out</option>
                                </select>
                                <div className="invalid-feedback">Please select status.</div>
                            </div>
                            <div className="col-md-12 mt-3">
                                <label htmlFor="status">Message<sup className="text-danger fw-bold"> *</sup></label>
                                <textarea name="message" id="message" cols="30" rows="7" className="form-control mt-2" required ></textarea>
                                <div className="invalid-feedback">Please type message.</div>
                            </div>
                            <div className="col text-end my-4">
                                <button type="button" className="btn btn-primary" onClick={(e) => saveLocationTicket(e)} disabled={tinyloader}>
                                    {tinyloader ?
                                        <div className="spinner-border spinner-border-sm mx-3" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div> : 'Submit'
                                    }
                                </button>
                                <button type="button" className="btn btn-outline-danger ms-2" data-bs-dismiss="modal" aria-label="Close" onClick={() => initialFormState('locationTicketForm')}>Close</button>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <h4 className="fw-bold">Latest Activity</h4>
                            <div className="col-md-12 border p-3 rounded overflow-auto" style={{ height: "450px" }}>
                                {ticketRecord && ticketRecord.chat_activity && ticketRecord.chat_activity.length > 0 ? ticketRecord.chat_activity.map((ele, key) => {
                                    return (
                                        <div className="col border p-2 rounded my-3" key={key}>
                                            <div className="col-md-12 d-flex justify-content-between border-bottom mb-2 py-2">
                                                <h6 className="fw-bold">{ele && ele.activity_user && `${ele.activity_user.first_name} ${ele.activity_user.last_name}`}</h6>
                                                <span className={`badge rounded-pill py-2 bg-${ele.status === 'sync' ? 'success' : ele.status === 'not_sync' ? 'danger' : ele.status === 'processing' ? 'warning' : 'primary'}`}>{ele && _.capitalize(_.replace(ele.status, '_', ' '))}</span>
                                                <span>{dateTimeFormat(ele.updated_at)}</span>
                                            </div>
                                            <div className="col-md-12 ">
                                                {ele.message}
                                            </div>
                                        </div>
                                    )
                                })
                                    :
                                    <div className="col d-flex justify-content-center align-items-center" style={{ height: "450px" }}>

                                        <h2 className="fw-bold">No activity found</h2>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </form>
            </Elements.ModalSection>
        </>
    )
}

export default ManageBusinessListing