import { Toast } from "bootstrap";
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import $ from 'jquery'
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import { GET_CONVERT_IMAGE_TO_BASE64, fetchData } from "./Service";

export const scrollbarSetToTop = () => {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'auto',
    });
}

export const noimageHandle = (ev) => {
    ev.target.src = "images/no-image.png"
    ev.target.classList.add("p-1");
    ev.target.title = "No Image";
}

export const toastNotify = (data) => {
    if (data.error || data.success) {
        const idGenerate = Math.floor((Math.random() * 1000000) + 1);
        let alertType = (data.success) ? 'success' : 'danger';

        const htmlToast = `<div id="` + idGenerate + `" class="toast fade bg-white mb-2 border-0" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="4000"><div class="d-flex bg-${alertType}"><div class="toast-body first-text-uppercase fw-medium1 fs-16px text-white">
            ${data.error ? data.error : data.success}
        </div><button type="button" class="btn-close ms-auto me-2 mt-2 mmwh-22px" data-bs-dismiss="toast" aria-label="Close" title="Close"></button></div>
        </div>`;
        document.getElementById('toastContainer').insertAdjacentHTML("afterbegin", htmlToast);

        var getIdToast = document.getElementById(idGenerate);
        var toast = new Toast(getIdToast);
        toast.show();
        getIdToast.addEventListener('hidden.bs.toast', function () {
            setTimeout(() => {
                this.remove();
            }, 600);
        });
    } else if (data.errors) {
        let i = 1;
        for (let key in data.errors) {
            $(`input[name="${key}"]`).closest('form').removeClass('was-validated');
            if (i === 1) {
                $(`input[name="${key}"]`).closest('form').find('.invalid-custom-feedback').remove();
            }

            $(`input[name="${key}"]`).after(`<div class="invalid-feedback invalid-custom-feedback d-block">${data.errors[key][0]}</div>`)
            
            let if_exist = $(`textarea[name="${key}"]`).length
            
            if(if_exist) {
                $(`textarea[name="${key}"]`).closest('form').removeClass('was-validated');
                if (i === 1) {
                    $(`textarea[name="${key}"]`).closest('form').find('.invalid-custom-feedback').remove();
                }

                $(`textarea[name="${key}"]`).after(`<div class="invalid-feedback invalid-custom-feedback d-block">${data.errors[key][0]}</div>`)

            }

            i++;
        }
    }
}

export const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result)
        };
        reader.readAsDataURL(blob);
    });
}

export const validateForm = (e, form_id = false) => {
    let error = 0;
    let forms = []

    if (form_id) {
        forms = [document.getElementById(form_id)]
    } else {
        forms = document.querySelectorAll('.needs-validation');
    }

    Array.prototype.slice.call(forms)
        .forEach(function (form) {
            if (!form.checkValidity()) {
                error++;
                e.preventDefault();
                e.stopPropagation();
            }
            form.classList.add('was-validated');
            form.querySelectorAll(':invalid').forEach(function (child) {
                if (child.closest('.react-select')) {
                    child.closest('.react-select').classList.add('is-invalid')
                }
            })
        })

    if (error) {
        return false
    } else {
        return true
    }
}

export const loadingData = (active) => {
    if (active) {
        document.querySelector('body').classList.add('loading-data');
    } else {
        document.querySelector('body').classList.remove('loading-data');
    }
}

export const tableSpinner = (e, tableid) => {
    let tbl = document.getElementById(tableid);
    if (tbl !== null) {
        if (e) {
            document.getElementById(tableid).classList.add('active');
        } else {
            document.getElementById(tableid).classList.remove('active');
        }
    }
}

export const intlTel_phone = (phone_id, country_id, country_code, dropdown = false) => {
    // Initialise the IntlTelInput
    const input = document.querySelector(phone_id);
    let iti_phone = intlTelInput(input, {
        formatOnDisplay: true,
        separateDialCode: true,
        allowDropdown: dropdown, 
        onlyCountries: ["us"],
    });
    // Load the utils JS
    window.intlTelInputGlobals.loadUtils("/static/js/intlTelInput-utils.js")
    // Create Instance of IntlTelInput
    var iti = window.intlTelInputGlobals.getInstance(document.querySelector(phone_id));

    if (country_id) {
        // Populate the country dropdown
        for (var i = 0; i < window.intlTelInputGlobals.getCountryData().length; i++) {
            var country = window.intlTelInputGlobals.getCountryData()[i];
            var optionNode = document.createElement("option");
            optionNode.value = country.iso2;
            var textNode = document.createTextNode(country.name);
            optionNode.appendChild(textNode);
            document.querySelector(country_id).appendChild(optionNode);
        }

        $(country_id).val(country_code);
        iti.setCountry(country_code);

        $(country_id).on('change', function () {
            iti.setCountry(this.value);
            validatePhone(iti_phone, phone_id)
        });
    }

    input.addEventListener('keyup', function () {
        validatePhone(iti_phone, phone_id)
    });

    input.addEventListener('change', function () {
        validatePhone(iti_phone, phone_id)
    });

    return iti_phone
}

export const validatePhone = (iti_phone, phone_id) => {
    let errorMap = ["Phone number is invalid.", "Country code is invalid.", "Phone number is too short.", "Phone number is too long.", "Phone number is invalid."];

    $(phone_id).removeClass('is-invalid');
    $(phone_id).siblings('.invalid-feedback').remove();
    if (!iti_phone.isValidNumber()) {
        var errorCode = iti_phone.getValidationError()
        $(phone_id).addClass('is-invalid')
        $(phone_id).after(`<div class="invalid-feedback">${errorMap[errorCode] ? errorMap[errorCode] : 'Phone number is invalid.'}</div>`)
        return false;
    } else {
        return true;
    }
}

export const reactSelectValidation = (e, field_id, multi = true) => {
    if (document.querySelector('#' + field_id).closest('form').classList.contains('was-validated')) {
        if ((multi === true && e.length) || (multi === false && e)) {
            document.querySelector('#' + field_id).classList.add("is-valid");
            document.querySelector('#' + field_id).classList.remove("is-invalid");
        } else {
            document.querySelector('#' + field_id).classList.add("is-invalid");
            document.querySelector('#' + field_id).classList.remove("is-valid");
        }
    }
}

export const setNumber = (iti_phone, no = false) => {
    if (!no) {
        no = iti_phone.getNumber();
    }
    iti_phone.setNumber(no)
}

export const starRating = (e, id) => {
    let wd = e.target.children[0];
    let rect = e.target.getBoundingClientRect();
    let x = e.clientX - rect.left;
    let passval = document.getElementById(id);
    if (x <= 25) {
        passval.value = 1;
        wd.style.width = 1 * 20 + '%';
    }
    if (x >= 26 && x <= 51) {
        passval.value = 2;
        wd.style.width = 2 * 20 + '%'
    }
    if (x >= 52 && x <= 77) {
        passval.value = 3;
        wd.style.width = 3 * 20 + '%'
    }
    if (x >= 78 && x <= 103) {
        passval.value = 4;
        wd.style.width = 4 * 20 + '%'
    }
    if (x >= 104 && x <= 130) {
        passval.value = 5;
        wd.style.width = 5 * 20 + '%'
    }
}

export const initialFormState = (formId, setData = false) => {
    [...document.querySelectorAll(`#${formId} .form-control, #${formId} .form-select`)].forEach((ele) => {
        ele.classList.remove('is-invalid');
    });
    [...document.querySelectorAll(`#${formId} .invalid-custom-feedback`)].forEach((ele) => {
        ele.classList.add('d-none');
    });
    document.getElementById(formId).classList.remove('was-validated')
    document.getElementById(formId).reset()
    if (setData) {
        setData(prevState => ({ ...prevState = '' }))
    }
    return setData;
}

export const __echoText = (data) => {
    return (
        data ? data : '-'
    )
}

export const floatNumber = (num) => {
    return parseFloat(num).toFixed(3).slice(0, -1);
}

export const dateFormat = (date) => {
    return new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC' });
}

export const dateTimeFormat = (date) => {
    return new Date(date).toLocaleString('en-US', { timeZone: 'UTC' });
}

export const passwordValidate = (e, setStrongPassword) => {
    setStrongPassword(true)
    if (e.target.value.length >= 6) {
        document.querySelector(".passhints li:nth-child(1)").classList.add('pwpass')
    } else {
        document.querySelector(".passhints li:nth-child(1)").classList.remove('pwpass')
        document.querySelector(".passhints li:nth-child(1)").classList.add('pwfail')
        setStrongPassword(false)
    }

    if (e.target.value.match(/[a-z]/g)) {
        document.querySelector(".passhints li:nth-child(2)").classList.add('pwpass')
    } else {
        document.querySelector(".passhints li:nth-child(2)").classList.remove('pwpass')
        document.querySelector(".passhints li:nth-child(2)").classList.add('pwfail')
        setStrongPassword(false)
    }

    if (e.target.value.match(/[A-Z]/g)) {
        document.querySelector(".passhints li:nth-child(3)").classList.add('pwpass')
    } else {
        document.querySelector(".passhints li:nth-child(3)").classList.remove('pwpass')
        document.querySelector(".passhints li:nth-child(3)").classList.add('pwfail')
        setStrongPassword(false)
    }

    if (e.target.value.match(/[0-9]/g)) {
        document.querySelector(".passhints li:nth-child(4)").classList.add('pwpass')
    } else {
        document.querySelector(".passhints li:nth-child(4)").classList.remove('pwpass')
        document.querySelector(".passhints li:nth-child(4)").classList.add('pwfail')
        setStrongPassword(false)
    }

    if (e.target.value.match(/[^a-zA-Z0-9 ]+/)) {
        document.querySelector(".passhints li:nth-child(5)").classList.add('pwpass')
    } else {
        document.querySelector(".passhints li:nth-child(5)").classList.remove('pwpass')
        document.querySelector(".passhints li:nth-child(5)").classList.add('pwfail')
        setStrongPassword(false)
    }

    if (e.target.value.length === 0) {
        document.querySelectorAll(".passhints li").forEach(function (el) {
            el.classList.remove('pwfail')
            setStrongPassword(false)
        });
    }
}

export const downloadPdf = async (id, name, fullwidth = false, fullheight = true) => {
    var element = document.getElementById(id)
    html2canvas(element, {
        allowTaint: true,
        useCORS: true,
        logging: true,
        // scale: window.devicePixelRatio,
        scale: 2,
        quality: 4,
        letterRendering: true,
        width: fullwidth ? element.clientWidth : window.innerWidth,
        height: element.clientHeight > window.innerHeight ? element.clientHeight : window.innerHeight,
    }).then(function (canvas) {
        loadingData(false)
        var pdfName = name ? name : 'AMAZIO_';
        pdfName = pdfName + dateFormat(new Date());
        var imgData = canvas.toDataURL('image/png');
        var doc = new jsPDF({
            format: 'A4',
            orientation: 'portrait',
        });
        if (fullheight) {
            doc.addImage(imgData, 'PNG', 5, 5, element.clientWidth > 1440 ? 240 : 200, 290);
        } else {
            doc.addImage(imgData, 'PNG', 5, 5, element.clientWidth > 1440 ? 240 : 200, 220);
        }
        doc.save(pdfName + '.pdf');
    });
}

export const downloadOnboarding = async (id, name = null, setTinyLoader = false) => {
    setTinyLoader(true)
    //This function will download all pdf data in one single page and size and content of the targeted div dos'nt matter
    const input = document.getElementById(id);
    const pdf = new jsPDF('p', 'pt', 'a4');
    const inputHeight = input.clientHeight;
    const inputWidth = input.clientWidth;

    // Set PDF size to match the content size
    pdf.internal.pageSize.height = inputHeight;
    pdf.internal.pageSize.width = inputWidth;

    const canvas = await html2canvas(input, { scale: 2 }); // Scale for better resolution
    setTinyLoader(false)
    const imgData = canvas.toDataURL('image/png');
    pdf.addImage(imgData, 'PNG', 0, 0, inputWidth, inputHeight);
    var pdfName = name ?? 'AMAZIO_';
        pdfName = `${pdfName}${dateFormat(new Date())}.pdf`;
    pdf.save(pdfName);
}

export const srcToBase64 = (url, res) => {
    fetchData(`${GET_CONVERT_IMAGE_TO_BASE64}?url=${url}`, 'GET', '', true, false, (response) => {
        if (response.records) {
            res(response.records)
        }
    });
}

export const zohoChat = () => {
    const url = 'https://salesiq.zoho.com/widget';
    const widgetCode = 'a4af5eb95281904723549d6ff5177dc61fb4b3c5a9944a608856ba17f7c864fa';
    const script = document.createElement('script');
    script.setAttribute("type", "text/javascript");
    script.setAttribute("id", "scriptid");
    let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${widgetCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${url}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`
    script.appendChild(document.createTextNode(code));
    document.body.appendChild(script);
}

export const removeErrorValidation = (formId) => {
    [...document.querySelectorAll(`#${formId} .form-control, #${formId} .form-select, #${formId} .react-select`)].forEach((ele) => {
        ele.classList.remove('is-invalid');
    });

    [...document.querySelectorAll(`#${formId} .invalid-custom-feedback`)].forEach((ele) => {
        ele.classList.add('d-none');
    });
}

export const state = {AL:"Alabama", AK : 'Alaska', AZ : 'Arizona', AR : 'Arkansas', CA : 'California', CO : 'Colorado', CT : 'Connecticut', DE : 'Delaware', DC : 'District Of Columbia', FL : 'Florida', GA : 'Georgia', HI : 'Hawaii', ID : 'Idaho', IL : 'Illinois', IN : 'Indiana', IA : 'Iowa', KS : 'Kansas', KY : 'Kentucky', LA : 'Louisiana', ME : 'Maine', MD : 'Maryland', MA : 'Massachusetts', MI : 'Michigan', MN : 'Minnesota', MS : 'Mississippi', MO : 'Missouri', MT : 'Montana', NE : 'Nebraska', NV : 'Nevada', NH : 'New Hampshire', NJ : 'New Jersey', NM : 'New Mexico', NY : 'New York', NC : 'North Carolina', ND : 'North Dakota', OH : 'Ohio', OK : 'Oklahoma', OR : 'Oregon', PA : 'Pennsylvania', RI : 'Rhode Island', SC : 'South Carolina', SD : 'South Dakota', TN : 'Tennessee', TX : 'Texas', UT : 'Utah', VT : 'Vermont', VA : 'Virginia', WA : 'Washington', WV : 'West Virginia', WI : 'Wisconsin', WY : 'Wyoming'}