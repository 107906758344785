import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Routes, Route, useNavigate, useLocation, redirect } from "react-router-dom";
import { Context } from './Context';
import { LayoutOfFront, LayoutOfAsideAndHeader } from './LayoutOfAsideAndHeader';

/*Start | Import for Fron Pages*/
import Home from '../modules/Front/Home';
import AllServices from '../modules/Front/AllServices';
import Industries from '../modules/Front/Industries/Industries';
import Pricing from '../modules/Front/Pricing';
import Digital from '../modules/Front/Digital';
import DigitalContent from '../modules/Front/DigitalContent';
import KnowledgeBase from '../modules/Front/KnowledgeBase';
import RequestDemo from '../modules/Front/RequestDemo';
import KnowledgeTags from '../modules/Front/KnowledgeTags';
import Websites from '../modules/Front/Websites';
import CompetitiveAnalytics from '../modules/Front/CompetitiveAnalytics';
import PrintMedia from '../modules/Front/PrintMedia';
import VoiceSearchPro from '../modules/Front/VoiceSearchPro';
import CookiePolicy from '../modules/Front/CookiePolicy';
import PrivacyPolicy from '../modules/Front/PrivacyPolicy';
import TermsAndConditions from '../modules/Front/TermsAndConditions';
import ContactUs from '../modules/Front/ContactUs';
import AboutUs from '../modules/Front/AboutUs';
import Reputation from '../modules/Front/Reputation';
import OnboardingForm from '../modules/Front/OnboardingForm';
import Hospitality from '../modules/Front/Industries/Hospitality';
import Retail from '../modules/Front/Industries/Retail';
import Food from '../modules/Front/Industries/Food';
import Healthcare from '../modules/Front/Industries/Healthcare';
import Insurance from '../modules/Front/Industries/Insurance';
import Government from '../modules/Front/Industries/Government';
import Franchises from '../modules/Front/Industries/Franchises';
import Automotive from '../modules/Front/Industries/Automotive';
import SmallBusiness from '../modules/Front/Industries/SmallBusiness';
import FacebookSetup from '../modules/Front/FacebookSetup';
import GoogleBusinessVerification from '../modules/Front/GoogleBusinessVerification';
import ManageServices from '../modules/Front/ManageServices';
import Resources from '../modules/Front/Resources'
/*End | Import for Front Pages*/

// import { LayoutOfAsideAndHeader} from './LayoutOfAsideAndHeader';
import Login from '../modules/Auth/Login';
import ResetPassword from '../modules/Auth/ResetPassword';
import Dashboard from '../modules/Dashboard/Dashboard';
import ManageUsers from '../modules/ManageUsers/ManageUsers';
import ManageBusinessListing from '../modules/ManageBusinessListing/ManageBusinessListing';
import DashboardAdmin from '../modules/DashboardAdmin/DashboardAdmin';
import ErrorPage from './ErrorPage';
import SalesAgent from '../modules/SalesAgent/SalesAgent';
import Support from '../modules/Support/Support';
import ManagePlan from '../modules/Accounting/ManagePlan';
import BookKeeping from '../modules/Accounting/BookKeeping';
import PromoCode from '../modules/Accounting/PromoCode';
import { fetchData, GET_AUTH_USER_INFO } from './Service';
import RateUs from '../modules/Support/RateUs';
import DemoRequest from '../modules/Support/DemoRequest';
import WebsiteSupport from '../modules/Support/WebsiteSupport';
import ManageFaq from '../modules/Support/ManageFaq';
import RequestCallBack from '../modules/Support/RequestCallBack';
import AddFaq from '../modules/Support/AddFaq';
import ManageSubscriber from '../modules/ManageSubscriber/ManageSubscriber';
import UserActivity from '../modules/UserActivity/UserActivity';
import PaymentInvoice from '../modules/Accounting/PaymentInvoice';
import Onboarding from '../modules/SocialMediaManager/Onboarding';
import ChangePassword from '../modules/Auth/ChangePassword';
import MyProfile from '../modules/Auth/MyProfile';
import Manage from '../modules/SocialMediaManager/Manage';
import PostingApproval from '../modules/SocialMediaManager/PostingApproval';
import Report from '../modules/SocialMediaManager/Reports';
import SocialMedia from '../modules/Front/SocialMedia'
import CompetitiveAnalysis from '../modules/ReputationMangement/CompetitiveAnalysis';
import ReviewResponse from '../modules/ReputationMangement/ReviewResponse';
import SegmentAnalysis from '../modules/ReputationMangement/SentimentAnalysis';
import ViewMapping from '../modules/SSOMapping/ViewMapping';
import UserSupport from '../modules/Support/UserSupport';
import UserWebsiteSupport from '../modules/Website/UserWebsiteSupport';
import Imprints from '../modules/Imprints/Imprints';
import Arclight from '../modules/Imprints/Arclight';
import BusinessCard from '../modules/Imprints/BusinessCard';
import CedPortal from '../modules/Imprints/CedPortal';
import Flyer from '../modules/Imprints/Flyer';
import SelectLocation from '../modules/Website/SelectLocation';
import CreateTicket from '../modules/Support/CreateTicket';
import AccountSetting from '../modules/UserAccount/AccountSetting';
import SignUp from '../modules/Front/SignUp';
import ViewLocation from '../modules/ManageBusinessListing/ViewLocation';
import SocailInsight from '../modules/DashboardAdmin/SocailInsight';
import ReviewByMonth from '../modules/DashboardAdmin/ReviewByMonth';
import Listing from '../modules/DashboardAdmin/Listing';
import ReputationDashboard from '../modules/DashboardAdmin/ReputationDashboard';
import CEDLogin from '../modules/Front/CEDLogin';
import GenerateCedLoginToken from '../modules/Website/GenerateCedLoginToken';
import CedCustomPortalList from '../modules/Imprints/CedCustomPortalList';
import ArclightList from '../modules/Imprints/ArclightList';
import BusinessCardList from '../modules/Imprints/BusinessCardList';
import VmiFlyerList from '../modules/Imprints/VmiFlyerList';
import SettingPanel from '../modules/SettingPanel/SettingPanel';
import ManageAdmins from '../modules/ManageAdmins/ManageAdmins';
import ProtectedRoute from './ProtectedRoute';
import GMBBusinessLocation from '../modules/GMBBusinessLocation/GMBBusinessLocation';
import ActiveLocation from '../modules/GMBBusinessLocation/ActiveLocation';
import OnBoardingList from '../modules/Onboarding/OnboardingList';

export default function Routing() {

    let token = localStorage.getItem('amazio-token');
    const [context, setContext] = useState(JSON.stringify({ token: token, auth: [] }));
    const [routLoader, setRoutLoader] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    // useLayoutEffect(() => {
    //     if ( location.pathname?.match('/blogs/*')) {
    //         window.location.href =('https://blog.amazio.com/case-studies')
    //     }
    //     if (location.pathname == '/blogs') {
    //         window.location.href =('https://blog.amazio.com/')
    //     }
    //     if ( location.pathname?.match('/case-studies')) {
    //         window.location.href =('https://blog.amazio.com/case-studies')
    //     }
    // }, [location.pathname])

    useEffect(() => {
        
        if (token) {
            fetchData(GET_AUTH_USER_INFO, 'GET', '', true, false, (res) => {
                if (res.records) {
                    setContext(prevState => ({
                        ...prevState,
                        auth: res.records
                    }))
                }
                setRoutLoader(true);
            });

        } else {
            if (location.pathname.match('/admin/*') || location.pathname.match('/user/*')) {
                navigate('/log-in')
            }
        }

        
    }, [token, location, navigate]);

    return (
        <Context.Provider value={[context, setContext]} >
            <Routes>
                {/* Start | Front Pages */}
                <Route path="/" caseSensitive={false} element={<LayoutOfFront />}>
                    <Route path="/" caseSensitive={false} element={<Home />} />
                    {/* <Route path="/test" caseSensitive={false} element={<Test />} /> */}
                    <Route path="/home" caseSensitive={false} element={<Home />} />
                    <Route path="/services" caseSensitive={false} element={<AllServices />} />
                    <Route path="/industries" caseSensitive={false} element={<Industries />} />
                    <Route path="/plans-pricing" caseSensitive={false} element={<Pricing />} />
                    <Route path="/business-listing-management-usa" caseSensitive={false} element={<Digital />} />
                    <Route path="/digital-content-imaging-services" caseSensitive={false} element={<DigitalContent />} />
                    <Route path="/social-media-management" caseSensitive={false} element={<SocialMedia />} />
                    <Route path="/manage-services" caseSensitive={false} element={<ManageServices />} />

                    <Route path="/industries/hospitality" caseSensitive={false} element={<Hospitality />} />
                    <Route path="/industries/retail" caseSensitive={false} element={<Retail />} />
                    <Route path="/industries/food" caseSensitive={false} element={<Food />} />
                    <Route path="/industries/healthcare" caseSensitive={false} element={<Healthcare />} />
                    <Route path="/industries/insurance" caseSensitive={false} element={<Insurance />} />
                    <Route path="/industries/government" caseSensitive={false} element={<Government />} />
                    <Route path="/industries/franchises" caseSensitive={false} element={<Franchises />} />
                    <Route path="/industries/automotive" caseSensitive={false} element={<Automotive />} />
                    <Route path="/industries/small-business" caseSensitive={false} element={<SmallBusiness />} />

                    <Route path="/knowledge-base" caseSensitive={false} element={<KnowledgeBase />} />
                    <Route path="/request-demo" caseSensitive={false} element={<RequestDemo />} />
                    <Route path="/web-mobile-informational-tags" caseSensitive={false} element={<KnowledgeTags />} />
                    <Route path="/web-app-development-maintenance" caseSensitive={false} element={<Websites />} />
                    <Route path="/competitive-intelligence-insights-analytics" caseSensitive={false} element={<CompetitiveAnalytics />} />
                    <Route path="/print-media" caseSensitive={false} element={<PrintMedia />} />
                    <Route path="/voice-search-pro" caseSensitive={false} element={<VoiceSearchPro />} />
                    <Route path="/facebook-setup" caseSensitive={false} element={<FacebookSetup />} />
                    <Route path="/gmb-verification-process" caseSensitive={false} element={<GoogleBusinessVerification />} />

                    <Route path="/log-in" caseSensitive={false} element={<Login />} />
                    <Route path="/sign-up" caseSensitive={false} element={<SignUp />} />
                    <Route path="/reset-password" caseSensitive={false} element={<ResetPassword />} />
                    <Route path="/cookie-policy" caseSensitive={false} element={<CookiePolicy />} />
                    <Route path="/privacy-policy" caseSensitive={false} element={<PrivacyPolicy />} />
                    <Route path="/terms-conditions" caseSensitive={false} element={<TermsAndConditions />} />
                    <Route path="/contact-us" caseSensitive={false} element={<ContactUs />} />
                    <Route path="/about-us" caseSensitive={false} element={<AboutUs />} />
                    <Route path="/reviews-reputation-sentiment-analysis" caseSensitive={false} element={<Reputation />} />
                    <Route path="/onboarding-form" caseSensitive={false} element={<OnboardingForm />} />
                    <Route path="/resources" caseSensitive={false} element={<Resources />} />
                    <Route path="/cedlogin" caseSensitive={false} element={<CEDLogin />} />
                    <Route path="/generate-ced-login-token" caseSensitive={false} element={<GenerateCedLoginToken />} />

                    {/* new redirected routes */}



                </Route>
                {/* End | Front Pages */}

                {context && context.auth &&
                    <Route path={context.auth.user_group_id === 1 ? 'admin' : 'users'} caseSensitive={false} element={<LayoutOfAsideAndHeader />}>

                        <Route path="choose-location" caseSensitive={false} element={<Dashboard />} />
                        <Route path="reputation-dashboard" caseSensitive={false} element={<ProtectedRoute permission="Reputation Management Reviews"><ReputationDashboard /></ProtectedRoute>} />
                        {context.auth.super_admin === 'yes' && <>
                            <Route path="manage-admins-list" caseSensitive={false} element={<ManageAdmins />} />
                        </> }
                        <Route path="manage-users-list" caseSensitive={false} element={context.auth.user_group_id === 1 ? <ProtectedRoute permission="Manage Users"><ManageUsers /></ProtectedRoute> : <ErrorPage />} />
                        <Route path="sale-agent-list" caseSensitive={false} element={<ProtectedRoute permission="Sales Agent"><SalesAgent /></ProtectedRoute>} />
                        <Route path="manage-business-list" caseSensitive={false} element={<ProtectedRoute permission="Manage Location"><ManageBusinessListing /></ProtectedRoute>} />

                        <Route path='view-tickets-list' caseSensitive={false} element={<ProtectedRoute permission="Supports"><Support /></ProtectedRoute>} />

                        <Route path="rate-us-list" caseSensitive={false} element={<ProtectedRoute permission="Rate Us"><RateUs /></ProtectedRoute>} />
                        <Route path="request-demo-list" caseSensitive={false} element={<ProtectedRoute permission="Demo Requests"><DemoRequest /></ProtectedRoute>} />
                        <Route path="view-website-support" caseSensitive={false} element={<ProtectedRoute permission="Website Admin"><WebsiteSupport /></ProtectedRoute>} />
                        <Route path="faqs-list" caseSensitive={false} element={<ProtectedRoute permission="Manage FAQs"><ManageFaq /></ProtectedRoute>} />
                        <Route path="add-faqs" caseSensitive={false} element={<AddFaq />} />
                        <Route path="add-unique-url" caseSensitive={false} element={<ProtectedRoute permission="Onboarding"><Onboarding /></ProtectedRoute>} />
                        <Route path="request-to-call" caseSensitive={false} element={<ProtectedRoute permission="Request to call"><RequestCallBack /></ProtectedRoute>} />
                        <Route path="admin-dashboard" caseSensitive={false} element={<ProtectedRoute permission="Common Dashboard"><DashboardAdmin /></ProtectedRoute>} />
                        {/* <Route path="soci-dashboard" caseSensitive={false} element={<ProtectedRoute permission="Social Media Dashboard"><SocailInsight /></ProtectedRoute>} /> */}
                        <Route path="soci-dashboard" caseSensitive={false} element={<ProtectedRoute permission="Social Media Dashboard"><SocailInsight /></ProtectedRoute>} />
                        <Route path="dashboard-listing" caseSensitive={false} element={<Listing />} />
                        <Route path="soci-dashboard-review-by-month" caseSensitive={false} element={<ReviewByMonth />} />
                        <Route path="pricing-list" caseSensitive={false} element={<ProtectedRoute permission="Manage Plan & Prices"><ManagePlan /></ProtectedRoute>} />
                        <Route path="book-keeping" caseSensitive={false} element={<ProtectedRoute permission="Book Keeping"><BookKeeping /></ProtectedRoute>} />
                        <Route path="promo-code" caseSensitive={false} element={<ProtectedRoute permission="Promo Code"><PromoCode /></ProtectedRoute>} />
                        <Route path="manage-subscribers-list" caseSensitive={false} element={<ProtectedRoute permission="Manage Subscribers"><ManageSubscriber /></ProtectedRoute>} />
                        <Route path="user-activity" caseSensitive={false} element={<ProtectedRoute permission="User Activity"><UserActivity /></ProtectedRoute>} />
                        <Route path="sso-mapping" caseSensitive={false} element={<ProtectedRoute permission="CED Mapping"><ViewMapping /></ProtectedRoute>} />
                        <Route path="payment-invoice-list" caseSensitive={false} element={<PaymentInvoice />} />
                        <Route path="change-password" caseSensitive={false} element={<ProtectedRoute permission="Profile"><ChangePassword /></ProtectedRoute>} />
                        <Route path="my-profile" caseSensitive={false} element={<ProtectedRoute permission="Profile"><MyProfile /></ProtectedRoute>} />
                        <Route path="manage" caseSensitive={false} element={<ProtectedRoute permission="Social Media Manager"><Manage /></ProtectedRoute>} />
                        <Route path="posting-approval" caseSensitive={false} element={<ProtectedRoute permission="Posting Approval"><PostingApproval /></ProtectedRoute>} />
                        <Route path="reports" caseSensitive={false} element={<ProtectedRoute permission="Reports"><Report /></ProtectedRoute>} />
                        <Route path="competitive-analysis" caseSensitive={false} element={<ProtectedRoute permission="Reputation Management Reviews"><CompetitiveAnalysis /></ProtectedRoute>} />
                        <Route path="review-response" caseSensitive={false} element={<ProtectedRoute permission="Review Respond"><ReviewResponse /></ProtectedRoute>} />
                        <Route path="sentiment-analysis" caseSensitive={false} element={<ProtectedRoute permission="Reputation Management Reviews"><SegmentAnalysis /></ProtectedRoute>} />
                        <Route path="view-location/:location_id/:site_status" caseSensitive={false} element={<ProtectedRoute permission="Business listing"><ViewLocation /></ProtectedRoute>} />
                        <Route path="view-location" caseSensitive={false} element={<ProtectedRoute permission="Business listing"><ViewLocation /></ProtectedRoute>} />
                        <Route path="manage-gmb-location" caseSensitive={false} element={<ProtectedRoute permission="GMB Business Location"><GMBBusinessLocation /></ProtectedRoute>} />
                        <Route path="active-locations" caseSensitive={false} element={<ProtectedRoute permission="GMB Active Location"><ActiveLocation /></ProtectedRoute>} />
                        {(context.auth.super_admin === 'no' && context.auth.user_group_id === 1) || context.auth.super_admin === 'yes' && <>
                            <Route path="ced-custom-portal" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><CedCustomPortalList /></ProtectedRoute>} />
                            <Route path="arclight-list" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><ArclightList /></ProtectedRoute>} />
                            <Route path="business-card-list" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><BusinessCardList /></ProtectedRoute>} />
                            <Route path="vmi-flyer-list" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><VmiFlyerList /></ProtectedRoute>} />
                            <Route path="setting-panel" caseSensitive={false} element={<SettingPanel />} />
                        </>}

                        {/* User Route */} 
                        <Route path="account-setting" caseSensitive={false} element={<AccountSetting />} />
                        <Route path="support" caseSensitive={false} element={<UserSupport />} />
                        <Route path="website-support" caseSensitive={false} element={<ProtectedRoute permission="Website"><UserWebsiteSupport /></ProtectedRoute>} />
                        <Route path="imprints" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><Imprints /></ProtectedRoute>} />
                        <Route path="ced-portal" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><CedPortal /></ProtectedRoute>} />
                        <Route path="arclight" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><Arclight /></ProtectedRoute>} />
                        <Route path="business-card" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><BusinessCard /></ProtectedRoute>} />
                        <Route path="flyer" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><Flyer /></ProtectedRoute>} />
                        <Route path="select-location" caseSensitive={false} element={<SelectLocation />} />
                        <Route path="create-ticket" caseSensitive={false} element={<ProtectedRoute permission="Supports"><CreateTicket /></ProtectedRoute>} />
                        <Route path="ced-custom-portal" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><CedCustomPortalList /></ProtectedRoute>} />
                        <Route path="arclight-list" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><ArclightList /></ProtectedRoute>} />
                        <Route path="business-card-list" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><BusinessCardList /></ProtectedRoute>} />
                        <Route path="vmi-flyer-list" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><VmiFlyerList /></ProtectedRoute>} />
                        <Route path="onboarding-details" caseSensitive={false} element={<OnBoardingList />} />
                        <Route path="*" element={<ErrorPage />} />
                    </Route>}

                {(!token || routLoader) && <Route path="*" element={<ErrorPage />} />}
            </Routes>
        </Context.Provider>
    )
}
